import { FormattedMessage } from "react-intl";
import React, { FC, useCallback } from "react";
import { OrderStatus } from "../../types/order";

import { Block, BlockTitle } from "./style";
import {
  Step,
  StepDescription,
  StepNumber,
  StepTitle,
} from "./style/next-steps";

interface NextStepsProps {
  currentStatus: string;
}

const NextSteps: FC<NextStepsProps> = ({ currentStatus }) => {
  const getCurrentStep = useCallback(() => {
    switch (currentStatus) {
      case OrderStatus.CLEANING:
        return 0;
      case OrderStatus.READY:
        return 1;
      case OrderStatus.COMPLETED:
        return 2;
      case OrderStatus.CANCELED:
      case OrderStatus.NEW:
      case OrderStatus.AWAITING_PICKUP:
      default:
        return -1;
    }
  }, [currentStatus]);

  return (
    <Block>
      <BlockTitle>
        <FormattedMessage id="orderConfirmation.next.title" />
      </BlockTitle>

      {Array.from({ length: 3 }).map((_, i) => (
        <Step key={i} isLit={i <= getCurrentStep()}>
          <StepNumber isLit={i <= getCurrentStep()}>{i + 1}</StepNumber>
          <StepTitle>
            <FormattedMessage
              id={`orderConfirmation.next.step${i + 1}.title`}
            />
          </StepTitle>
          <StepDescription>
            <FormattedMessage
              id={`orderConfirmation.next.step${i + 1}.description`}
            />
          </StepDescription>
        </Step>
      ))}
    </Block>
  );
};

export default NextSteps;
