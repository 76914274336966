import { useMemo } from "react";
import qs from "query-string";

import Check from "../../images/check-circle.svg";
import CanceledIcon from "../../images/canceled.svg";
import DownloadApp from "./download-app";
import { FormattedMessage } from "react-intl";
import { getSingleOrder } from "../../store/orders/actions";
import Help from "./help";
import Layout from "../layout";
import NextSteps from "./next-steps";
import { Order, OrderStatus } from "../../types/order";
import OrderSummary from "./summary";
import React, { FC, useEffect, useState } from "react";
import useThunkDispatch from "../../hooks/use-thunk-dispatch";
import { useToast, TOAST_TYPE } from "../../ui/toast";
import { useIsDesktop } from "../../hooks/use-is-desktop";
import YourOrders from "./orders";

import {
  Background,
  Block,
  BlocksWrapper,
  Column,
  Title,
  Wrapper,
} from "./style";
import OrderReschedule from "../order-reschedule";

interface MainProps {
  order: Order;
  location: Location;
}

function getTitleId(status: string): string {
  switch (status) {
    case OrderStatus.AWAITING_PICKUP:
    case OrderStatus.CLEANING:
    case OrderStatus.READY:
      return "orderConfirmation.tracking.inProgress";
    case OrderStatus.COMPLETED:
      return "orderConfirmation.tracking.over";
    case OrderStatus.CANCELED:
      return "orderConfirmation.tracking.canceled";
    case OrderStatus.NEW:
    default:
      return "orderConfirmation.title";
  }
}

const Main: FC<MainProps> = ({ order, location }) => {
  const { status } = order;
  const isDesktop = useIsDesktop();

  const isCanceled = useMemo<boolean>(() => {
    return "CANCELED" === status;
  }, [status]);

  return (
    <>
      <Title>
        {isCanceled ? <CanceledIcon /> : <Check />}
        <FormattedMessage
          id={getTitleId(order.status)}
          values={{ orderNb: order.id }}
        />
      </Title>
      <BlocksWrapper>
        {isDesktop ? (
          <>
            <Column>
              <OrderSummary order={order} />
              <Block>
                <OrderReschedule location={location} fromOrderConfirmation />
              </Block>
              <Help />
              <YourOrders />
            </Column>
            <Column>
              <NextSteps currentStatus={order.status} />
              <DownloadApp />
            </Column>
          </>
        ) : (
          <Column>
            <OrderSummary order={order} />
            <NextSteps currentStatus={order.status} />
            <DownloadApp />
            <YourOrders />
            <Help />
          </Column>
        )}
      </BlocksWrapper>
    </>
  );
};

interface OrderConfirmationPageProps {
  location: Location;
}

const OrderConfirmationPage: FC<OrderConfirmationPageProps> = ({
  location,
}) => {
  const [error, setError] = useState<boolean>(false);
  const [order, setOrder] = useState<Order>();
  let orderId = "";
  if (typeof window !== "undefined") {
    orderId =
      typeof qs.parse(location.search).order === "string"
        ? (qs.parse(location.search).order as string)
        : "";
  }
  const dispatch = useThunkDispatch();
  const { showToast } = useToast();

  useEffect(() => {
    const fetchOrder = async (): Promise<void> => {
      if (orderId) {
        const response = await dispatch(getSingleOrder(orderId));
        if (response.success && response.order) {
          setOrder(response.order);
        } else {
          const err = response.error ? response.error : "error.unknown";
          showToast(<FormattedMessage id={err} />, { type: TOAST_TYPE.ERROR });
          setError(true);
        }
      }
    };
    fetchOrder();
  }, [orderId]);

  return (
    <Layout header={false} footer={false} menuState="cart" progressBarStep={4}>
      <Background>
        <Wrapper>
          {orderId && !error ? (
            <>
              {order ? (
                <Main order={order} location={location} />
              ) : (
                <FormattedMessage id="loading" />
              )}
            </>
          ) : (
            <FormattedMessage id="error.order.loading" />
          )}
        </Wrapper>
      </Background>
    </Layout>
  );
};

export default OrderConfirmationPage;
