import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import React, { FC } from "react";
import WastInfo from "../../config/wast";

import Mail from "../../images/mail.svg";
import Phone from "../../images/phone.svg";
import Time from "../../images/time-grey.svg";

import { Block, BlockTitle } from "./style";
import { Icon, Line, Link } from "./style/help";

const Help: FC = () => (
  <Block>
    <BlockTitle>
      <FormattedMessage id="orderConfirmation.help.title" />
    </BlockTitle>

    <Line>
      <Icon>
        <Phone />
      </Icon>
      <Link href={`tel:${WastInfo.phone.raw}`}>{WastInfo.phone.formatted}</Link>
    </Line>
    <Line>
      <Icon>
        <Mail />
      </Icon>
      <Link href={`mailto:${WastInfo.mail}`}>{WastInfo.mail}</Link>
    </Line>
    {/* <Line>
      <Icon>
        <Time />
      </Icon>
      <span>
        <FormattedHTMLMessage id="orderConfirmation.help.opening" />
      </span>
    </Line> */}
  </Block>
);

export default Help;
