export interface Product {
  name: string;
  shortDescription: string;
  description: string;
  quantity: number;
  total: number;
}

interface Address {
  number: string;
  street: string;
  country: string;
  postalCode: string;
  city: string;
  notes: string;
  lat: number;
  lng: number;
}

export interface Time {
  startTime: string;
  endTime: string;
  actualStartTime?: string;
  actualEndTime?: string;
}

interface Recipient {
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

interface Location {
  lat: number;
  lng: number;
}

interface Facility {
  name: string;
  location: Location;
}

interface Rating {
  rate: number | null;
  comment: string | null;
  isSkipped: boolean;
}

export interface Order {
  id: string;
  facility: Facility | null;
  serviceClass: string;
  status: string;
  notes: string;
  deliveryFee: number;
  discountsTotal: number;
  subTotal: number;
  total: number;
  feesTotal: number;
  items: Product[];
  createdAt: string;
  updatedAt: string;
  pickupAddress: Address;
  dropoffAddress: Address;
  pickupTime: Time;
  dropoffTime: Time;
  recipient: Recipient;
  rating: Rating;
}

export enum OrderStatus {
  NEW = "NEW",
  AWAITING_PICKUP = "AWAITING_PICKUP",
  CLEANING = "CLEANING",
  READY = "READY",
  COMPLETED = "COMPLETED",
  CANCELED = "CANCELED",
}
