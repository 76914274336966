import styled from "styled-components";

export const Line = styled.div`
  align-items: flex-start;
  color: ${({ theme }): string => theme.colors.secondaryText};
  display: flex;
  margin-bottom: 0.8em;
`;

export const Link = styled.a`
  color: ${({ theme }): string => theme.colors.secondaryText};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const Icon = styled.span`
  align-items: center;
  display: flex;
  height: 25px;
  margin-right: 20px;
  width: 25px;
`;
