import { FormattedMessage } from "react-intl";
import { navigate } from "gatsby";
import React, { FC } from "react";

import { Block, BlockTitle } from "./style";
import { Button } from "./style/orders";

const Orders: FC = () => (
  <Block>
    <BlockTitle>
      <FormattedMessage id="orderConfirmation.orders.title" />
    </BlockTitle>
    <Button onClick={(): Promise<void> => navigate("/orders")}>
      <FormattedMessage id="orderConfirmation.orders.button" />
    </Button>
  </Block>
);

export default Orders;
