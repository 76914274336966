import dayjs from "dayjs";
import { FormattedMessage } from "react-intl";
import { Order } from "../../types/order";
import React, { FC } from "react";

import { Block } from "./style";
import { DateHour, Label } from "./style/summary";
import userLanguage from "../hooks/useLanguage";

interface SummaryProps {
  order: Order;
}


const Summary: FC<SummaryProps> = ({ order }) => {
  const locale = userLanguage();
  return (
    <Block>
      <Label>
        <FormattedMessage id="general.pickup" />
      </Label>
      <DateHour>
        {dayjs(order.pickupTime.startTime)
          .locale(locale)
          .format("dddd DD MMM")}
        ,{" "}
        {dayjs(order.pickupTime.startTime)
          .locale(locale)
          .format("HH:mm")}{" "}
        -{" "}
        {dayjs(order.pickupTime.endTime)
          .locale(locale)
          .format("HH:mm")}
      </DateHour>
      <Label>
        <FormattedMessage id="general.dropoff" />
      </Label>
      <DateHour>
        {dayjs(order.dropoffTime.startTime)
          .locale(locale)
          .format("dddd DD MMM")}
        ,{" "}
        {dayjs(order.dropoffTime.startTime)
          .locale(locale)
          .format("HH:mm")}{" "}
        -{" "}
        {dayjs(order.dropoffTime.endTime)
          .locale(locale)
          .format("HH:mm")}
      </DateHour>
  
      <Label>
        <FormattedMessage id="general.deliveryType" />
      </Label>
      <span>{order.serviceClass}</span>
  
      <Label>
        <FormattedMessage id="general.address" />
      </Label>
      <span>
        {order.pickupAddress.number} {order.pickupAddress.street}
      </span>
      <span>
        {order.pickupAddress.postalCode} {order.pickupAddress.city}
      </span>
      {!!order.pickupAddress.notes && <span>{order.pickupAddress.notes}</span>}
    </Block>
  )
};

export default Summary;
