import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import React, { FC } from "react";

import { Block, BlockTitle } from "./style";
import {
  BadgeAppleStoreLink,
  BadgeGooglePlayLink,
  DownloadDescription,
  DownloadLinkWrapper,
} from "./style/download-app";

import AppleStoreEn from "../../images/badge-apple-store_en.svg";
import AppleStoreFr from "../../images/badge-apple-store_fr.svg";

import GooglePlayBadgeEn from "../images/badge-google-play_en";
import GooglePlayBadgeFr from "../images/badge-google-play_fr";
import userLanguage from "../hooks/useLanguage";

const BadgeAppleStore: FC = () => {
  const lang = userLanguage();
  switch (lang) {
    case "fr":
      return <AppleStoreFr />;
    case "en":
    default:
      return <AppleStoreEn />;
  }
};

const BadgeGooglePlay: FC = () => {
  const lang = userLanguage();
  switch (lang) {
    case "fr":
      return <GooglePlayBadgeFr />;
    case "en":
    default:
      return <GooglePlayBadgeEn />;
  }
};

const DownloadApp: FC = () => (
  <Block>
    <BlockTitle>
      <FormattedHTMLMessage id="orderConfirmation.download.title" />
    </BlockTitle>
    <DownloadDescription>
      <FormattedMessage id="orderConfirmation.download.description" />
    </DownloadDescription>
    <DownloadLinkWrapper>
      <BadgeAppleStoreLink
        href={`itms-apps://itunes.apple.com/app/apple-store/${process.env.GATSBY_APPLE_STORE_ID}`}
      >
        <BadgeAppleStore />
      </BadgeAppleStoreLink>
      <BadgeGooglePlayLink
        href={`https://play.google.com/store/apps/details?id=${process.env.GATSBY_GOOGLE_PLAY_ID}`}
      >
        <BadgeGooglePlay />
      </BadgeGooglePlayLink>
    </DownloadLinkWrapper>
  </Block>
);

export default DownloadApp;
