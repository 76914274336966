import styled from "styled-components";

const MARGIN = "15px;";

export const Background = styled.div`
  background-color: ${({ theme }): string => theme.colors.grey};
`;

export const BlocksWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    flex-direction: row;
  }
`;

export const Block = styled.div`
  background-color: ${({ theme }): string => theme.colors.white};
  border-radius: 10px;
  font-family: ${({ theme }): string => theme.fonts.main};
  font-size: 14px;
  display: flex;
  flex-direction: column;
  margin-bottom: ${MARGIN};
  padding: 15px;
  width: 100%;

  &:last-child {
    margin-bottom: 0px;
  }

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    padding: 20px;
  }
`;

export const BlockTitle = styled.h4`
  margin-bottom: 25px;
`;

export const Column = styled.div`
  margin-bottom: ${MARGIN};
  width: 100%;

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    margin-right: ${MARGIN};
    width: 50%;

    &:last-child {
      margin-right: 0px;
    }
  }
`;

export const Title = styled.h4`
  display: flex;
  text-align: center;

  svg {
    fill: ${({ theme }): string => theme.colors.secondary};
    height: 50px;
    margin-right: 10px;
    width: 50px;
  }

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    svg {
      height: 30px;
      width: 30px;
    }
  }
`;

export const Wrapper = styled.div`
  max-width: 800px;
  margin: auto;
  padding: 20px;
`;
