import styled from "styled-components";
import Button from "../../../ui/button";
import OAddressButton from "../../shopping-cart/address-button";

export const Container = styled.div<{ fromOrderConfirmation?: boolean }>`
  flex: 1;
  display: flex;
  justify-content: center;
  background-color: ${({ theme: { colors }, fromOrderConfirmation }): string =>
    fromOrderConfirmation ? "#fff" : colors.grey};
`;

interface Props {
  isFirstChild?: boolean;
}

export const SummaryLineContainer = styled.div<Props>`
  margin-top: ${({ isFirstChild }): number => (!isFirstChild ? 14 : 0)}px;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ContentContainer = styled.div<{ fromOrderConfirmation?: boolean }>`
  margin-top: ${({ fromOrderConfirmation }): number =>
    fromOrderConfirmation ? 0 : 16}px;
  padding: ${({ fromOrderConfirmation }): number =>
    fromOrderConfirmation ? 0 : 22}px;
  width: ${({ fromOrderConfirmation }): number =>
    fromOrderConfirmation ? 100 : 90}%;

  @media (min-width: 700px) {
    width: ${({ fromOrderConfirmation }): number =>
      fromOrderConfirmation ? 100 : 50}%;
  }
`;

export const PickupDropOffContainer = styled.div`
  margin-bottom: 24px;
`;

export const Title = styled.span`
  margin-bottom: 10px;
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme: { colors } }): string => colors.primary};
`;

export const GreyTitle = styled.span`
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  font-size: 12px;
  color: ${({ theme: { colors } }): string => colors.lightGrey};
  margin-bottom: 10px;
`;

export const AddressButton = styled(OAddressButton)`
  margin-bottom: 20px;
`;

export const SummaryButton = styled.div`
  background-color: ${({ theme: { colors } }): string => colors.white};
  height: 48px;
  border-radius: 10px;
  margin-bottom: 20px;
  padding-horizontal: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const OptionnalInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MoreInfoInput = styled.textarea`
  border: none;
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  font-size: 14px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const RescheduleButton = styled(Button)`
  margin-top: 20px;
  height: 48px;
  object-fit: contain;
  border-radius: 57px;
  width: 286px;
  color: ${({ theme: { colors } }): string => colors.white};
  background-color: ${({ theme: { colors } }): string => colors.secondary};
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  margin-bottom: 19px;
`;

export const InputDark = styled.span`
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  font-size: 14px;
  color: ${({ theme: { colors } }): string => colors.primary};
  margin-left: 15px;
`;
export const H3Dark = styled.span`
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  font-size: 14px;
  color: ${({ theme: { colors } }): string => colors.primary};
  font-weight: bold;
`;

export const InputSecondary = styled.span`
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  font-size: 14px;
  color: ${({ theme: { colors } }): string => colors.secondary};
  margin-right: 15px;
`;

export const CheckboxWrapper = styled.div`
  margin: 10px;
`;
