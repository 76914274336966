import React, { FC, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { navigate } from "gatsby";
import { FormattedMessage, useIntl } from "react-intl";
import "dayjs/locale/en";
import "dayjs/locale/fr";
import qs from "query-string";

import {
  Container,
  ContentContainer,
  PickupDropOffContainer,
  RescheduleButton,
  ButtonContainer,
} from "./style";
import PickupDropOffRangePicker from "../pickup-drop-off-range-picker";

import useThunkDispatch from "../../hooks/use-thunk-dispatch";
import { useToast, TOAST_TYPE } from "../../ui/toast";

import { AppState } from "../../store";
import { Order } from "../../types/order";
import { rescheduleOrderRequest } from "../../store/orders/actions";
import { getSingleOrder } from "../../store/orders/actions";

interface Props {
  location: Location;
  fromOrderConfirmation?: boolean;
}

const OrderReschedule: FC<Props> = ({ fromOrderConfirmation }) => {
  const dispatch = useThunkDispatch();
  const { showToast } = useToast();
  const intl = useIntl();

  const [error, setError] = useState<boolean>(false);
  const [order, setOrder] = useState<Order>();
  const [loading, setLoading] = useState<boolean>(false);
  const rescheduleValid = useSelector<AppState, boolean>(
    state => state.shoppingCart.rescheduleValid
  );

  let orderId = "";
  if (typeof window !== "undefined") {
    orderId =
      typeof qs.parse(location.search).order === "string"
        ? (qs.parse(location.search).order as string)
        : "";
  }

  useEffect(() => {
    const fetchOrder = async (): Promise<void> => {
      if (orderId) {
        const response = await dispatch(getSingleOrder(orderId));
        if (response.success && response.order) {
          setOrder(response.order);
        } else {
          const err = response.error ? response.error : "error.unknown";
          showToast(<FormattedMessage id={err} />, { type: TOAST_TYPE.ERROR });
          setError(true);
        }
      }
    };
    fetchOrder();
  }, [orderId]);

  const handleReschedulePress = useCallback(async () => {
    setLoading(true);

    if (!rescheduleValid) {
      showToast(intl.formatMessage({ id: "notValid" }), {
        type: TOAST_TYPE.ERROR,
      });
      setLoading(false);

      return;
    }
    let response;
    if (order) response = await dispatch(rescheduleOrderRequest(order));
    if (response?.success) {
      navigate(`/orders`);
    } else {
      showToast(response?.error ? response.error : "Error", {
        type: TOAST_TYPE.ERROR,
      });
    }

    setLoading(false);
  }, [dispatch, navigate, showToast, rescheduleValid, intl, order]);

  return (
    <Container fromOrderConfirmation={fromOrderConfirmation}>
      <ContentContainer fromOrderConfirmation={fromOrderConfirmation}>
        {orderId && !error ? (
          <>
            {order ? (
              <>
                <FormattedMessage id="orderReschedule" />
                <PickupDropOffContainer>
                  <PickupDropOffRangePicker reschedule order={order} />
                </PickupDropOffContainer>
                <ButtonContainer>
                  <RescheduleButton
                    onClick={handleReschedulePress}
                    loading={loading}
                  >
                    {intl.formatMessage({ id: "orderReschedule" })}
                  </RescheduleButton>
                </ButtonContainer>
              </>
            ) : (
              <FormattedMessage id="loading" />
            )}
          </>
        ) : (
          <FormattedMessage id="error.order.loading" />
        )}
      </ContentContainer>
    </Container>
  );
};

export default OrderReschedule;
