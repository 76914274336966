import styled from "styled-components";

interface CanBeLit {
  isLit: boolean;
}

export const Step = styled.div<CanBeLit>`
  border-left: 1px solid
    ${({ theme, isLit }): string =>
      isLit ? theme.colors.secondary : theme.colors.secondaryText};
  margin-left: 1em;
  padding-left: 2em;
  padding-bottom: 1em;
  position: relative;

  &:last-child {
    border-left: none;
    padding-bottom: 0;
  }
`;

export const StepDescription = styled.span`
  color: ${({ theme }): string => theme.colors.secondaryText};
  display: block;
  width: 100%;
`;

export const StepNumber = styled.span<CanBeLit>`
  align-items: center;
  background-color: ${({ theme }): string => theme.colors.white};
  border: 1px solid
    ${({ theme, isLit }): string =>
      isLit ? theme.colors.secondary : theme.colors.secondaryText};
  border-radius: 50%;
  color: ${({ theme, isLit }): string =>
    isLit ? theme.colors.secondary : theme.colors.secondaryText};
  display: flex;
  height: 2em;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0px;
  transform: translateX(-50%);
  width: 2em;
`;

export const StepTitle = styled.span`
  font-weight: bold;
  font-size: 1.1em;
  margin-bottom: 0;
  width: 100%;
`;
