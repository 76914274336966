import styled from "styled-components";

export const BadgeAppleStoreLink = styled.a`
  height: 40px;
  width: auto;
`;

export const BadgeGooglePlayLink = styled.a`
  height: 40px;
  width: 132px;
`;

export const DownloadDescription = styled.span`
  color: ${({ theme }): string => theme.colors.secondaryText};
`;

export const DownloadLinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 15px;

  a {
    margin-right: 10px;

    &:last-child {
      margin-right: 0px;
    }
  }
`;
