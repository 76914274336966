import styled from "styled-components";

export const DateHour = styled.span`
  color: ${({ theme }): string => theme.colors.secondary};
  font-size: 1.2em;
  font-weight: bold;
`;

export const Label = styled.span`
  color: ${({ theme }): string => theme.colors.secondaryText};
  font-size: 0.8em;
  margin-top: 0.8em;

  &:first-child {
    margin-top: 0;
  }
`;
